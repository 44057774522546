var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-sheet',{staticClass:"d-flex pa-4 align-center",attrs:{"color":_vm.$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-card-subtitle',{staticClass:"pa-0 col-md-6"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file-document")]),_vm._v(" Resumen Últimos Contratos ")],1),_c('v-spacer'),_c('v-text-field',{attrs:{"filled":"","hide-details":"","append-icon":"mdi-magnify","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.ultimosContratos,"search":_vm.search},scopedSlots:_vm._u([{key:"item.CodigoContrato",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/contratos/detalles?codigoContrato=" + (item.CodigoContrato) + "&idContrato=" + (item.IdContrato)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(Math.abs(Number(item.CodigoContrato))))])]}}],null,true)},[_c('span',[_vm._v("Detalles del contrato")])])]}},{key:"item.SituacionContrato",fn:function(ref){
var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.SituacionContrato}})]}},{key:"item.CodigoCUPS",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/detalles_cups?cups=" + (item.CodigoCUPS)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.CodigoCUPS))])]}}],null,true)},[_c('span',[_vm._v("Detalles del CUPS")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }