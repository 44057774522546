<template>
	<v-card outlined>
		<v-sheet :color="$vuetify.theme.dark ? '#1e1e1e' : '#f1f1f1'" class="d-flex pa-4 align-center">
			<v-row no-gutters align="center">
				<v-card-subtitle class="pa-0 col-md-6">
					<v-icon small>mdi-file-document</v-icon>&nbsp;Resumen Últimos
					Contratos
				</v-card-subtitle>
				<v-spacer />
				<v-text-field
					filled
					hide-details
					append-icon="mdi-magnify"
					dense
					v-model="search"
				/>
			</v-row>
		</v-sheet>
		<v-data-table
			hide-default-footer
			:headers="headers"
			:items="ultimosContratos"
			:search="search"
		>
			<template v-slot:item.CodigoContrato="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							link
							:to="`/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
							color="secondary"
							text
							>{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn
						>
					</template>
					<span>Detalles del contrato</span>
				</v-tooltip>
			</template>

			<template v-slot:item.SituacionContrato="{ item }">
				<status-chip :value="item.SituacionContrato" />
			</template>

			<template v-slot:item.CodigoCUPS="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							link
							:to="`/detalles_cups?cups=${item.CodigoCUPS}`"
							color="secondary"
							text
							>{{ item.CodigoCUPS }}</v-btn
						>
					</template>
					<span>Detalles del CUPS</span>
				</v-tooltip>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
// import axios from "redaxios";
import { parseDate, processToken } from "@/utils/index.js";
export default {
	components: {
		StatusChip: () => import("@/components/StatusChip.vue"),
	},
	data() {
		return {
			search: null,
			ultimosContratos: [],
			headers: [
				{ text: "Contrato", value: "CodigoContrato" },
				{ text: "Nombre", value: "DenominacionCliente" },
				{ text: "CUPS", value: "CodigoCUPS" },
				{ text: "Situación", value: "SituacionContrato" },
			],
		};
	},
	mounted() {
		let str = this.$store.getters.getDatosEmpresa;

		const { IdUsuario, IdAgente } = str.iss;
		const IdEmpresa = this.$store.getters.getIdEmpresa

		var ourDate = new Date();

		var pastDate = ourDate.getDate() - 14;
		ourDate.setDate(pastDate);

		let fecha = parseDate(ourDate, false, false).split(" ").reverse().join("-");

		axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/Agente/ResumenContratosAlta/?TablasMaestras_FinderFilterResumenDatosAgenteAPIDTO={IdEmpresa:${IdEmpresa},IdUsuario:${IdUsuario},IdAgente:${IdAgente},FechaDesde:'${fecha}'}`,
		}).then((res) => {
			this.ultimosContratos = processToken(res.data).iss.filter(c => c.SituacionContrato != 'Anulado');
		});
	},
};
</script>

<style>
</style>